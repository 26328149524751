const data=[
    {
        id:1,
        title:'outStation ',
        image:'out.jpg'

    },
    {
        id:2,
        title:'car rental with driver',
        image:'driver.png'

    },
    {
        id:3,
        title:'Airport transper',
        image:'airport.jpeg'

    },
   
]
export default data;
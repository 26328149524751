import img1 from '../../Assets/Gallert.jsx/1.jpeg'
import img2 from '../../Assets/banner.jpg'
import img3 from '../../Assets/Gallert.jsx/3.jpeg'
import img4 from '../../Assets/Gallert.jsx/4.jpeg'
import img5 from '../../Assets/Gallert.jsx/5.jpeg'
import img6 from '../../Assets/Gallert.jsx/6.jpeg'
import img7 from '../../Assets/Gallert.jsx/7.jpeg'
import img8 from '../../Assets/Gallert.jsx/8.png'
import img9 from '../../Assets/Gallert.jsx/9.jpeg'
import img10 from '../../Assets/Gallert.jsx/10.jpeg'
import img11 from '../../Assets/Gallert.jsx/11.jpeg'
import img12 from '../../Assets/Gallert.jsx/12.jpeg'
import img13 from '../../Assets/Gallert.jsx/13.jpeg'
import img14 from '../../Assets/Gallert.jsx/14.jpeg'
import img15 from '../../Assets/Gallert.jsx/15.jpeg'
import img16 from '../../Assets/Gallert.jsx/16.jpeg'
import img17 from '../../Assets/Gallert.jsx/17.jpeg'
import img18 from '../../Assets/Gallert.jsx/18.jpeg'
import img19 from '../../Assets/Gallert.jsx/19.jpeg'

import img20 from '../../Assets/Gallert.jsx/21.jpeg'
import img21 from '../../Assets/Gallert.jsx/20.jpeg'

import img22 from '../../Assets/Gallert.jsx/22.jpeg'
import img23 from '../../Assets/Gallert.jsx/23.jpeg'
import img24 from '../../Assets/Gallert.jsx/24.jpeg'
import img25 from '../../Assets/Gallert.jsx/25.jpeg'
import img26 from '../../Assets/Gallert.jsx/26.jpeg'
import img27 from '../../Assets/Gallert.jsx/27.jpeg'
import img28 from '../../Assets/Gallert.jsx/28.jpeg'
import img29 from '../../Assets/Gallert.jsx/29.jpeg'
import img30 from '../../Assets/Gallert.jsx/30.jpeg'

import img31 from '../../Assets/Gallert.jsx/31.jpeg'

import img32 from '../../Assets/Gallert.jsx/32.jpeg'

import img33 from '../../Assets/Gallert.jsx/33.jpeg'

import img34 from '../../Assets/Gallert.jsx/34.jpeg'

import img35 from '../../Assets/Gallert.jsx/35.jpeg'

import img36 from '../../Assets/Gallert.jsx/36.jpeg'

import img37 from '../../Assets/Gallert.jsx/37.jpeg'

import img38 from '../../Assets/Gallert.jsx/38.jpeg'

import img39 from '../../Assets/Gallert.jsx/39.jpeg'

import img40 from '../../Assets/Gallert.jsx/40.jpeg'

import img41 from '../../Assets/Gallert.jsx/41.jpeg'

import img42 from '../../Assets/Gallert.jsx/42.jpeg'

import img43 from '../../Assets/Gallert.jsx/43.jpeg'

import img44 from '../../Assets/Gallert.jsx/44.jpeg'



import img45 from '../../Assets/Gallert.jsx/45.jpeg'

import img46 from '../../Assets/Gallert.jsx/46.jpeg'

import img47 from '../../Assets/Gallert.jsx/47.jpeg'

import img48 from '../../Assets/Gallert.jsx/48.jpeg'

import img49 from '../../Assets/Gallert.jsx/49.jpeg'

import img50 from '../../Assets/Gallert.jsx/50.jpeg'

import img51 from '../../Assets/Gallert.jsx/51.jpeg'

import img52 from '../../Assets/Gallert.jsx/52.jpeg'

import img53 from '../../Assets/Gallert.jsx/53.jpeg'

import img54 from '../../Assets/Gallert.jsx/54.jpeg'

import img55 from '../../Assets/Gallert.jsx/55.jpeg'

import img56 from '../../Assets/Gallert.jsx/56.jpeg'

import img57 from '../../Assets/Gallert.jsx/57.jpeg'

import img58 from '../../Assets/Gallert.jsx/58.jpeg'

import img59 from '../../Assets/Gallert.jsx/59.jpeg'

// import img60 from '../../Assets/Gallert.jsx/60.jpeg'



const data=[
        {
        img:img1
    },{
        img:img2
    },
    {
        img:img3
    },
    {
        img:img5
        },
        {
            img:img6
            },
            {
                img:img7
                },
                {
                    img:img8
                    },
                        {
                            img:img4
                        },
    {
        img:img9
    },
    {
        img:img10
    },
    {
        img:img11
    },
    {
        img:img12
    },
    {
        img:img21
    },
    {
        img:img13
    },
    {
        img:img14
    },
    {
        img:img15
    },
    {
        img:img16
    },
    {
        img:img17
    },
    {
        img:img18
    },
    {
        img:img19
    }
    ,
    {
        img:img20
    },
    {
        img:img22
    },
    {
        img:img23
    } ,
    {
        img:img24
    },
    {
        img:img25
    },
    {
        img:img26
    },
    {
        img:img27
    },
    {
        img:img28
    },
    {
        img:img29
    },
    {
        img:img30
    },
    {
        img:img31
    } ,
    {
        img:img32
    }   ,
    {
        img:img33
    },
    {
        img:img34
    },
    {
        img:img35
    },
    {
        img:img36
    },
    {
        img:img37
    },{
        img:img38
    },
    {
        img:img39
    },
    {
        img:img40
    },
    {
        img:img41
    },
    {
        img:img42
    },
    {
        img:img43
    },
    {
        img:img44
    },
    {
        img:img45
    },
    {
        img:img51
    },
    {
        img:img46
    },
    {
        img:img47
    },
    {
        img:img48
    },
    {
        img:img49
    },
    {
        img:img50
    },
    {
        img:img52
    },
    {
        img:img53
    },
    {
        img:img53
    },
    {
        img:img54
    },
    {
        img:img55
    },
    {
        img:img56
    },  {
        img:img57
    },
    {
        img:img58
    },
    {
        img:img59
    },
    // {
    //     img:img60
    // }
]
export default data;
